<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />
    <v-col sm="12" class="main-body" v-else>
      <delete-modal
        :dialog="dialog"
        @close="closeModal"
        description="Are You sure you want to delete this Booking Admin"
        @deleteAction="deleteBookingAdmin"
        :doneLoading="deleteLoading"
      />
      <v-col sm="12" class=" tw-flex tw-justify-between tw-items-center">
        <button
          class="tw-flex tw-items-center tw-mt-6 overview-btn"
          @click="selectTab"
        >
          <icons name="arrow-back" :color="companyColor" />
          <span class="tw-ml-4" :style="{ color: companyColor }"
            >All Booking Admins</span
          >
        </button>
        <div class="tw-flex tw-items-center">
          <button @click="selectEditTab">
            <icons name="edit" :color="companyColor" />
          </button>
          <button class="tw-pl-5" @click="openDeleteModal">
            <icons name="trash" :color="companyColor" />
          </button>
        </div>
      </v-col>
      <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-8 tw-px-16">
        <div class="tw-px-12">
          <div class="admin-img-container">
            <img
              :src="adminData.profileImage"
              v-if="adminData.profileImage"
              :alt="adminData.firstname"
            />
          </div>
        </div>

        <v-row class="tw-mt-6 tw-px-12 tw-justify-between tw-hidden lg:tw-flex">
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">First Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{
                adminData.firstname
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Street No.</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{
                adminData.address.houseNumber
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Last Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{
                adminData.lastname
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Street Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{
                adminData.address.streetName
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Email</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-lowercase">{{
                adminData.email
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Mobile</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-uppercase">{{
                adminData.phoneNumber
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Town/City</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{
                adminData.address.city
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Terminal</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{
                adminData.terminalAssigned
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">State</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{
                adminData.address.state
              }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Country</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{
                adminData.address.country
              }}</v-col>
            </div>
          </v-col>
        </v-row>
        <div class="tw-flex tw-w-full tw-flex-col lg:tw-hidden tw-mt-5">
          <v-col sm="12" lg="6" class="label-key ">First Name</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.firstname
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key ">Last Name</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.lastname
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key ">Email</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.email
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key ">Phone Number</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.phoneNumber
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key ">Terminal</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.terminalAssignedName
            }}</v-col>
          <v-divider/>
          <v-col sm="12" lg="6" class="label-key ">House Number</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.address.houseNumber
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key ">Street Name</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.address.streetName
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key ">City</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.address.city
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key ">State</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.address.state
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key ">Country</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              adminData.address.country
            }}</v-col>
        </div>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import {
  getBookingAdminById,
  deleteBookingAdmin,
} from "@/services/api/APIService";
import Icons from "../../reuseables/Icons";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
export default {
  name: "SingleBookingAdmin",
  components: { Icons, Loader, DeleteModal },
  data() {
    return {
      companyData: {},
      adminData: {},
      companyColor: "#004AAD",
      loading: false,
      adminId: "",
      dialog: false,
      deleteLoading: false,
    };
  },
  methods: {
    openDeleteModal() {
      console.log("click me");
      this.dialog = true;
    },
    closeModal() {
      this.dialog = !this.dialog;
      this.adminId = "";
    },
    selectTab() {
      // console.log("i'm here");
      this.$store.dispatch(
        "bookingAdminComponent/setCurrentComponent",
        "allBookingAdmins"
      );
      sessionStorage.setItem("bookingAdminComponent", "allBookingAdmins");
    },
    selectEditTab() {
      this.$store.dispatch(
        "bookingAdminComponent/setCurrentComponent",
        "editBookingAdmin"
      );
      sessionStorage.setItem("bookingAdminComponent", "editBookingAdmin");
      sessionStorage.setItem("adminId", this.adminData.id)
    },
    async deleteBookingAdmin() {
      this.deleteLoading = true;
      let data = {
        transportCompanyId: this.companyData.id,
        bookingAdminId: this.adminId,
      };
      try {
        const response = await deleteBookingAdmin(data);
        console.log(response);
        this.$displaySnackbar({
          message: response.data.details,
          success: true,
        });
        this.deleteLoading = false;
        this.selectTab();
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
        this.deleteLoading = false;
        this.closeModal();

        console.log(error.response);
      }
    },
    async getAdminById() {
      let data = {
        transportCompanyId: this.companyData.id,
        bookingAdminId: this.adminId,
      };
      try {
        const response = await getBookingAdminById(data);
        this.adminData = await response.data;
        this.loading = false;
        console.log(response);
      } catch (error) {
        console.log(error.response);
        this.loading = false;
      }
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let bookingAdminId = sessionStorage.getItem("bookingAdminId");
    if (bookingAdminId) {
      this.adminId = bookingAdminId;
    } else {
      return this.selectTab();
    }

    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }

    await this.getAdminById();
  },
};
</script>

<style lang="scss" scoped>
/* .main-body{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0!important;
} */
.view-link {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: rgba(0, 74, 173, 1);
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.card-div {
  img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }

  .header-id {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
}

.img-div {
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header-id {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12) !important;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  padding: 0;
  padding-left: 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0;
  padding-left: 5px;
}

.admin-img-container {
  width: 200px;
  height: 220px;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.label-key {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.label-value {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
