var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-20 lg:tw-mt-6 overview-btn",style:({ color: _vm.companyColor }),on:{"click":_vm.backToAllBookingAdmins}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyColor}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v("All Booking Admins")])],1),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl tw-px-5 lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full",attrs:{"sm":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addBookingAdmin)}}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"},[_c('p',{staticClass:"edit-header",style:({ color: _vm.companyColor })},[_vm._v(" New Booking Admin ")]),(_vm.bookingAdminData.id)?_c('icons',{attrs:{"name":"trash","color":_vm.companyColor}}):_vm._e()],1),_c('v-row',{staticClass:"upload-div tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Upload Photo")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose an image as a profile")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('button',{staticClass:"button-logo-input",class:_vm.image ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogoFile()}}},[(_vm.logoImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":_vm.companyColor,"indeterminate":""}}):_vm._e(),(_vm.logoImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.image,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("@/assets/camera.png"),"alt":""}})],1),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})])],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("First Name")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the First Name of the Booking Admin ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"First name e.g Tunji","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.bookingAdminData.firstname),callback:function ($$v) {_vm.$set(_vm.bookingAdminData, "firstname", $$v)},expression:"bookingAdminData.firstname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Last Name")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Last Name of the Booking Admin ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Last name e.g Oluwale","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.bookingAdminData.lastname),callback:function ($$v) {_vm.$set(_vm.bookingAdminData, "lastname", $$v)},expression:"bookingAdminData.lastname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Email (Optional)")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Email Address of the Booking Admin ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Booking Admin's Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Email e.g tunji@companyemail.com","solo":"","hide-details":"","type":"email"},model:{value:(_vm.bookingAdminData.email),callback:function ($$v) {_vm.$set(_vm.bookingAdminData, "email", $$v)},expression:"bookingAdminData.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Mobile")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Mobile Number ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('phone-number',{attrs:{"phone-number-exist":_vm.bookingAdminData.phoneNumber},on:{"getNumber":_vm.getNumber}})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Address")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Booking Admin's address ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-cursor-pointer",class:classes,on:{"click":_vm.handleShowAddressModal}},[_c('v-text-field',{attrs:{"placeholder":"371,Borno Way, Alagomeji Yaba, Lagos","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.getBookingAdminAddress),callback:function ($$v) {_vm.getBookingAdminAddress=$$v},expression:"getBookingAdminAddress"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:" tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Terminal")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose the Terminal for the Booking Admin. ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Terminal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [(Object.keys(_vm.staffData).length)?_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Terminal Name","solo":"","hide-details":"","type":"text","required":"","disabled":Object.keys(_vm.staffData).length > 0},model:{value:(_vm.staffData.terminalAssignedName),callback:function ($$v) {_vm.$set(_vm.staffData, "terminalAssignedName", $$v)},expression:"staffData.terminalAssignedName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1):_c('div',{class:classes},[_c('v-combobox',{attrs:{"items":_vm.terminals,"placeholder":"Choose Terminal","append-icon":"mdi-chevron-down","item-text":"terminalName","item-value":"id","solo":"","hide-details":"","color":_vm.companyColor},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.terminalName)+" "+_vm._s(item.address.city)+", "+_vm._s(item.address.state))])]}}],null,true),model:{value:(_vm.terminalId),callback:function ($$v) {_vm.terminalId=$$v},expression:"terminalId"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"},[_c('v-btn',{staticClass:"first-btn",style:({
              color: _vm.companyColor,
              border: '1px solid' + _vm.companyColor,
            }),attrs:{"text":""},on:{"click":_vm.backToAllBookingAdmins}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor }),attrs:{"text":"","type":"submit","loading":_vm.doneLoading}},[_vm._v(" Done ")])],1)],1)]}}])})],1),_c('modal',{attrs:{"section":"custom-address","address":_vm.bookingAdminData.address,"dialog":_vm.showAddressModal},on:{"handleAddress":_vm.handleAddress,"close":_vm.closeHandleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }