<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />

  <v-col sm="12" v-else>
    <button
        class="tw-flex tw-items-center tw-mt-20 lg:tw-mt-6 overview-btn"
        @click="backToAllBookingAdmins"
        :style="{ color: companyColor }">
      <icons name="arrow-back" :color="companyColor" />
      <span class="tw-ml-4">All Booking Admins</span>

    </button>

    <v-col
        sm="12"
        class="tw-bg-white tw-mt-9 tw-rounded-xl tw-px-5 lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full"
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(updateBookingAdmin)">
          <div
              class="tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"
          >
            <h6 class="edit-header" :style="{ color: companyColor }">
              Edit Booking Admin
            </h6>
            <div class="tw-cursor-pointer" @click="closeModal"><icons name="trash" :color="companyColor" /></div>

          </div>
          <!-- upload image -->
          <v-row class="upload-div tw-mb-5 tw-flex-col lg:tw-flex-row">
            <v-col sm="12" md="4" class="">
              <div class="form-label-div">
                <p class="label">Upload Photo</p>
                <p class="label-info">Choose an image as a profile</p>
              </div>
            </v-col>
            <v-col sm="12" md="8" class="">
              <button
                  class="button-logo-input"
                  type="button"
                  @click="onLogoFile()"
                  :class="image ? 'border-0' : ''"
              >
                <v-progress-circular
                    :size="100"
                    :width="3"
                    :color="companyColor"
                    indeterminate
                    style="position: absolute;"
                    v-if="logoImageLoading"
                ></v-progress-circular>

                <img
                    :src="image"
                    alt=""
                    class="button-logo-img"
                    v-if="logoImage"
                />
                <img
                    :src="bookingAdminData.profileImage"
                    alt=""
                    class="button-logo-img"
                    v-else-if="bookingAdminData.profileImage"
                />
                <img
                    src="@/assets/camera.png"
                    alt=""
                    class="button-logo-input-img"
                    v-else
                />
              </button>

              <input
                  type="file"
                  ref="logoImage"
                  accept="image/*"
                  style="display: none"
                  @change="onLogoImagePicked"
              />
            </v-col>
          </v-row>
          <!-- first name -->
          <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
            <v-col sm="12" md="4" class="">
              <div class="form-label-div">
                <p class="label">First Name</p>
                <p class="label-info">Type the First Name of the Booking Admin</p>
              </div>
            </v-col>
            <v-col sm="12" md="8" class="">
              <ValidationProvider
                  name="First Name"
                  rules="required"
                  v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                      placeholder="First name e.g Tunji"
                      v-model="bookingAdminData.firstname"
                      solo
                      hide-details
                      type="text"
                      required
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <!-- last name -->
          <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
            <v-col sm="12" md="4" class="">
              <div class="form-label-div">
                <p class="label">Last Name</p>
                <p class="label-info">
                  Type the Last Name of the Booking Admin
                </p>
              </div>
            </v-col>
            <v-col sm="12" md="8" class="">
              <ValidationProvider
                  name="Last Name"
                  rules="required"
                  v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                      placeholder="Last name e.g Oluwale"
                      v-model="bookingAdminData.lastname"
                      solo
                      hide-details
                      type="text"
                      required
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <!-- email -->
          <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
            <v-col sm="12" md="4" class="">
              <div class="form-label-div">
                <p class="label">Email</p>
                <p class="label-info">
                  Type the Email Address of the Booking Admin
                </p>
              </div>
            </v-col>
            <v-col sm="12" md="8" class="">
              <ValidationProvider
                  name="Booking Admin Email"

                  v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                      placeholder="Email e.g tunji@companyemail.com"
                      solo
                      hide-details
                      v-model="bookingAdminData.email"
                      type="email"

                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
          <!-- phone number -->
          <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
            <v-col sm="12" md="4" class="">
              <div class="form-label-div">
                <p class="label">Mobile</p>
                <p class="label-info">
                  Type the Mobile Number
                </p>
              </div>
            </v-col>
            <v-col sm="12" md="8" class="">
              <phone-number :phone-number-exist="bookingAdminData.phoneNumber"
                            @getNumber="getNumber" />
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
            <v-col sm="12" md="4" class="">
              <div class="form-label-div">
                <p class="label">Address</p>
                <p class="label-info">
                  Type the Booking Admin's address
                </p>
              </div>
            </v-col>
            <v-col sm="12" md="8" class="">
              <ValidationProvider
                  name="Address"
                  rules="required"
                  v-slot="{ classes, errors }"
              >
                <div class="tw-cursor-pointer" :class="classes" @click="handleShowAddressModal">
                  <v-text-field
                      placeholder="371,Borno Way, Alagomeji Yaba, Lagos"
                      solo
                      v-model="getBookingAdminAddress"
                      hide-details
                      type="text"
                      required
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-flex-col lg:tw-flex-row">
            <v-col sm="12" md="4" class="">
              <div class="form-label-div">
                <p class="label">Terminal</p>
                <p class="label-info">
                  Choose the Terminal for the Booking Admin.
                </p>
              </div>
            </v-col>
            <v-col sm="12" md="8" class="">
              <ValidationProvider
                  name="Terminal"
                  rules="required"
                  v-slot="{ classes, errors }"
              >
                <div class="" :class="classes" v-if="Object.keys(staffData).length">
                  <v-text-field
                      placeholder="Terminal Name"
                      solo
                      v-model="staffData.terminalAssignedName"
                      hide-details
                      type="text"
                      required
                      :disabled="Object.keys(staffData).length > 0"
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
                <div class="" :class="classes" v-else>
                  <v-select
                      disabled
                      :items="terminals"
                      placeholder="Choose Terminal"
                      append-icon="mdi-chevron-down"
                      item-text="terminalName"
                      item-value="id"
                      v-model="bookingAdminData.terminalAssignedId"
                      solo
                      hide-details
                      :color="companyColor"
                  ></v-select>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <div
              class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"
          >
            <v-btn
                @click="backToAllBookingAdmins"
                text
                class="first-btn"
                :style="{
                color: companyColor,
                border: '1px solid' + companyColor,
              }"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                class="second-btn"
                type="submit"
                :style="{ background: companyColor }"
                :loading="doneLoading">
              Done
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-col>
  </v-col>
    <delete-modal :dialog="showDialog" @close="closeModal"
                  @deleteAction="deleteAdmin"
                  :done-loading="dialogLoading"
                  description="Are you sure you want to delete this Booking Admin?"/>

    <modal section="custom-address" :address="bookingAdminData.address"
           :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>

  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary,
  getTransportCompanyTerminals,
  getBookingAdminById,
  updateBookingAdminById, deleteBookingAdmin,
} from "../../../services/api/APIService";
import Icons from "../../reuseables/Icons";
import Lga from "../../../assets/lga";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
import PhoneNumber from "@/components/reuseables/PhoneNumber";
import Modal from "@/components/reuseables/Modal.vue";
export default {
  name: "EditBookingAdmin",
  components: {Modal, PhoneNumber, DeleteModal, Loader, Icons},
  data() {
    return {
      bookingAdminData:{
        id: null,
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        address:{
          country: "",
          state: "",
          city: "",
          houseNumber: "",
          streetName: "",
        }
      },
      showAddressModal: false,
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      logoImage: "",
      logoImageUrl: "",
      logoImageLoading: false,
      image: "",
      companyColor :"",
      doneLoading : false,
      terminals : [],
      terminalId : "",
      bookingAdminId: "",
      companyData : {},
      loading: false,
      showDialog : false,
      dialogLoading : false,
      staffData:{}
    };
  },
  computed: {
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },
    getBookingAdminAddress(){
      if (this.bookingAdminData.address.houseNumber) {
        return this.toSentenceCase(this.bookingAdminData.address.houseNumber) + " "
            +this.toSentenceCase(this.bookingAdminData.address.streetName) +
            ", " + this.toSentenceCase(this.bookingAdminData.address.city) + " "
            +this.toSentenceCase(this.bookingAdminData.address.state)
            + " "+this.toSentenceCase(this.bookingAdminData.address.country)
      }
      else {
        return ""
      }
    },
  },
  methods: {

    handleShowAddressModal(){
      this.showAddressModal = true
    },

    closeHandleModal(){
      this.showAddressModal = !this.showAddressModal
    },

    handleAddress(address){
      this.bookingAdminData.address = address
    },

    toSentenceCase(text) {
      text = text.toLowerCase()
      return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
    },

    closeModal(){
      this.showDialog = !this.showDialog
    },
    backToAllBookingAdmins() {
      if (Object.keys(this.staffData).length){
        this.$store.dispatch(
            "terminalManagerBookingAdminComponent/setCurrentComponent",
            "allBookingAdmins"
        );
        sessionStorage.setItem(
            "terminalManagerBookingAdminComponent",
            "allBookingAdmins"
        );
      }
      else {
        this.$store.dispatch(
            "bookingAdminComponent/setCurrentComponent",
            "allBookingAdmins"
        );
        sessionStorage.setItem("bookingAdminComponent", "allBookingAdmins");
      }
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.logoImageUrl = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },

    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        console.log(response);
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {};
        if (this.bookingAdminData.address.country.name){
          data.country =this.bookingAdminData.address.country.name
        }
        else {
          data.country = this.bookingAdminData.address.country
        }
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        if (this.bookingAdminData.address.country.name === "Nigeria" || this.bookingAdminData.address.country === "Nigeria") {
          this.states.push({name: 'Rivers State'})
        }
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      if (this.bookingAdminData.address.country.name === "Nigeria" || this.bookingAdminData.address.country === "Nigeria") {
        this.cities = Lga[this.bookingAdminData.address.state.name || this.bookingAdminData.address.state]
      } else {
        try {
          this.getCitiesLoading = true;
          let data = {};
          if (this.bookingAdminData.address.country.name){
            data.country=this.bookingAdminData.address.country.name
                data.state= this.bookingAdminData.address.state.name
          }
          else {
            data.country=this.bookingAdminData.address.country
            data.state= this.bookingAdminData.address.state
          }
          const response = await getAllCitiesInAState(data);
          console.log(response);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },
    async getAllTerminals() {
      try {
        const response = await getTransportCompanyTerminals(
            this.companyData.id
        );
        this.terminals = response.data;
      } catch (error) {
        console.log(error.response);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      }
    },
    getNumber(number){
      this.bookingAdminData.phoneNumber=number
    },
    async getBookingAdminById() {
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.bookingAdminId = this.bookingAdminId
      try {
        const response = await getBookingAdminById(data);
        this.bookingAdminData = await response.data;
        await this.getState();
        await this.getCity();
        this.loading = false;
        console.log(response);
      } catch (error) {
        console.log(error.response);
        this.loading = false
      }
    },
    async updateBookingAdmin(){
      let data = this.bookingAdminData
      if (this.bookingAdminData.address.country.name){
        data.address.country = this.bookingAdminData.address.country.name
      }
      if (this.bookingAdminData.address.state.name){
        data.address.state = this.bookingAdminData.address.state.name
      }

      if (this.bookingAdminData.address.city.name){
        data.address.city =this.bookingAdminData.address.city.name
      }
      if (this.logoImageUrl){
        data.profileImage = this.logoImageUrl
      }
      data.terminalId = this.bookingAdminData.terminalAssignedId
      data.transportCompanyId =this.companyData.id
      data.bookingAdminId = this.bookingAdminData.id
      if (this.validatePhone(this.bookingAdminData.phoneNumber)){
        this.doneLoading = true;
        await updateBookingAdminById(data).then(()=>{
          this.clearData();
          this.backToAllBookingAdmins();
          this.doneLoading = false;
        }).
        catch(err=> {
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
          this.doneLoading = false;
        })
      }
  },
    async deleteAdmin(){
      this.dialogLoading = true
      let data={}
      data.transportCompanyId = this.companyData.id
      data.bookingAdminId = this.bookingAdminId
      await deleteBookingAdmin(data).then(() =>{
        this.dialogLoading = false
        this.closeModal()
        this.backToAllBookingAdmins()
      }).
      catch(err=> {
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
        this.dialogLoading = false;
      })
    },
    validatePhone(phoneNumber){
      // let num = /^\(?([0-9]{3})\)?([0-9]{3})?([0-9]{3})?([0-9]{4})$/;
      if(phoneNumber.length > 6) {
        return true;
      }
      else {
        this.$displaySnackbar({
          message: "Phone number not valid e.g 2348000000000",
          success: false,
        });
        return false;
      }

    },
    validEmail(email){
      let valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (email.match(valid)){
        return true
      }
      else {
        this.$displaySnackbar({
          message: "Email not valid e.g example@company.com",
          success: false,
        });
        return false;
      }
    },
    clearData() {
      this.image = "";
      this.bookingAdminData = {}
    },
  },
  mounted() {
    this.getCountries();
  },
  async created() {
    this.loading = true
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let staffData= JSON.parse(sessionStorage.getItem("staffData"))
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    if (staffData){
      this.staffData = staffData
    }
    let adminId = sessionStorage.getItem("adminId");
    if (adminId) {
      this.bookingAdminId = adminId;
      console.log(this.bookingAdminId, "the id")
    }
    await this.getAllTerminals();
    await this.getBookingAdminById()
  },
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 84px;
  width: 84px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 25px;
  height: 25px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
