<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import TerminalDashboard from "../terminal/ViewTerminalDashboard.vue";
import AddBookingAdmin from "./AddBookingAdmin";
import SingleBookingAdmin from "./SingleBookingAdmin";
import EditBookingAdmin from "./EditBookingAdmin";
import AllBookingAdminDashboard from "./AllBookingAdminDashboard";
import { mapGetters } from "vuex";

export default {
  name: "ViewBookingAdminRoute",
  computed: {
    ...mapGetters("bookingAdminComponent", ["currentComponent"])
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "terminalDashboard") {
        return TerminalDashboard;
      } else if (component === "allBookingAdmins") {
        return AllBookingAdminDashboard;
      } else if (component === "singleBookingAdmin") {
        return SingleBookingAdmin;
      } else if (component === "editBookingAdmin") {
        return EditBookingAdmin;
      } else if (component === "addBookingAdmin") {
        return AddBookingAdmin;
      }
    }
  },
  created() {
    let component = sessionStorage.getItem("bookingAdminComponent");

    if (!component) {
      sessionStorage.setItem("bookingAdminComponent", "allBookingAdmins");
      this.$store.dispatch("bookingAdminComponent/setCurrentComponent", "allBookingAdmins");
      this.displayTerminalComponent()

    } else {
      this.$store.dispatch("bookingAdminComponent/setCurrentComponent", component);
      this.displayTerminalComponent()
    }
  }
};
</script>

<style lang="scss" scoped></style>
